<template>
  <div class="brief">
    <div class="brief-content">
      <div class="describe">
        <div class="line" />
        <h2>主营业务</h2>
        <p>
          主营业务包括：网络货运（公铁水互通网络货运），软件服务，能源，新能源，网上商城及维保服务，通过互联网平台加数字化技术引用，在大数据的介入下，促使货运全网互联互通，借助智慧物流模式进行行业升级。
        </p>
      </div>
      <div class="img">
        <img src="./../../assets/img/zy.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "./../../assets/scss/base.scss";
.brief {
  .brief-content {
    .describe {
      p {
        line-height: 28px;
        font-size: 16px;
        color: #414141;
      }
      p:nth-child(4) {
        margin: 64px 0;
      }
    }
    .img {
      margin: 76px 0 250px 0;
      text-align: center;
    }
  }
}
</style>
